<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <delete-dialog />
    <v-row no-gutter>
      <v-col>
        <div class="headline">
          <v-icon left large color="primary">mdi-domain</v-icon>
          IAO Feedback
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="colorBasedonScore"
                class="mx-3"
                x-large>
                {{ iconBasedonScore }}
              </v-icon>
            </template>
            <span>Average IAO Score: {{ averageIncidentScore }}</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="auto">
        <v-btn small color="secondary" dark @click="summarizeFeedback">
          <v-icon small v-if="!isLoading" left>mdi-creation</v-icon>
          <v-progress-circular v-else indeterminate size="24"></v-progress-circular>
          Summarize Feedback
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <table-filter-dialog :projects="defaultUserProjects" />
      </v-col>
    </v-row>
    <v-dialog v-model="showIncidentFeedbackSummary" max-width="600px">
      <v-card>
        <v-card-title>BAO Feedback Summary</v-card-title>
        <v-card-text>
          <span v-html="incidentFeedbackSummary"></span>
        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="onLike">
              <v-icon>mdi-thumb-up-outline</v-icon>
            </v-btn>
            <v-btn icon @click="onDislike">
              <v-icon>mdi-thumb-down-outline</v-icon>
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showIncidentFeedbackSummary = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="ma-3 elevation-5">
                <!-- Rating -->
                <v-card-title>
                  <v-chip large>
                    <v-icon large left color="primary">mdi-star-outline</v-icon>
                    {{ item.rating }}
                  </v-chip>
                </v-card-title>
                <!-- Participant Info -->
                <v-card-subtitle class="mb-2">
                  <v-icon left color="primary">mdi-human-greeting</v-icon>
                  <template v-if="item.participant">
                    <participant :participant="item.participant" />
                  </template>
                  <span v-if="!item.participant">Anonymous</span>
                </v-card-subtitle>

                <!-- Rating -->
                <v-card-text>
                  <div class="mb-2">
                    <v-icon left color="primary">mdi-star-outline</v-icon>
                    Rating: {{ item.rating }}
                  </div>
                  <!-- Feedback -->
                  <div class="mb-2">
                    <v-icon left color="success">mdi-message-text-outline</v-icon>
                    Feedback: {{ item.feedback }}
                  </div>
                  <!-- Created At -->
                  <div class="mb-2">
                    <v-icon left color="grey darken-1">mdi-calendar-clock</v-icon>
                    Created At: {{ item.created_at | formatDate }}
                  </div>
                  <!-- Project -->
                  <div class="mb-2">
                      <project-card :project="item.project" />
                  </div>
                  <div class="mb-2">
                    <v-icon left color="info">mdi-domain</v-icon>
                     IAO: {{ item.incident.title }}
                    <v-list-item :to="{ name: 'IncidentTableEdit', params: { name: item.incident.name }}">
                      <v-icon small left color="info">mdi-domain</v-icon>
                      {{ item.incident.name }}
                      <v-icon right small color="primary">mdi-open-in-new</v-icon>
                    </v-list-item>
                  </div>
                </v-card-text>

                <!-- Actions -->
                <v-card-actions>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-icon>
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Delete Feedback</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.participant="{ item }">
              <participant v-if="item.participant" :participant="item.participant" />
            </template>
            <template v-slot:item.created_at="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
                </template>
                <span>{{ item.created_at | formatDate }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.project.name="{ item }">
              <project-card :project="item.project" />
            </template>
            <template v-slot:item.data-table-actions="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="removeShow(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { forEach } from "lodash"

import DeleteDialog from "@/feedback/incident/DeleteDialog.vue"
import Participant from "@/incident/Participant.vue"
import RouterUtils from "@/router/utils"
import TableFilterDialog from "@/feedback/incident/TableFilterDialog.vue"
import ProjectCard from "@/project/ProjectCard.vue"

export default {
  name: "FeedbackTable",

  components: {
    DeleteDialog,
    Participant,
    TableFilterDialog,
    ProjectCard,
  },

  data() {
    return {
      headers: [],
      showIncidentFeedbackSummary: false,
      incidentFeedbackSummary: null,
      isLoading: false,
    }
  },

  computed: {
    ...mapFields("incident_feedback", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters",
      "table.options.filters.incident",
      "table.options.filters.rating",
      "table.options.filters.feedback",
      "table.options.filters.participant",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
    ...mapFields("auth", ["currentUser.projects"]),

    defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
    averageIncidentScore() {
      if (!this.items || this.items.length === 0) {
        return 'No Data'
      }
      const scoreMapping = {
        "Very satisfied": 5,
        "Somewhat satisfied": 4,
        "Neither satisfied nor dissatisfied": 3,
        "Somewhat dissatisfied": 2,
        "Very dissatisfied": 1
      };
      let totalScore = 0
      forEach(this.items, (value) => {
        totalScore += scoreMapping[value.rating]
      })
      // Round to 2 decimal places
      return (totalScore / this.items.length).toFixed(2)
    },
    iconBasedonScore() {
      if (this.averageIncidentScore >= 4) {
        return "mdi-emoticon-excited-outline"
      } else if (this.averageIncidentScore >= 3) {
        return "mdi-emoticon-happy-outline"
      } else if (this.averageIncidentScore >= 2) {
        return "mdi-emoticon-neutral-outline"
      } else if (this.averageIncidentScore >= 1) {
        return "mdi-emoticon-sad-outline"
      } else {
        return "mdi-emoticon-confused-outline"; // Default icon if no score
      }
    },
    colorBasedonScore() {
      if (this.averageIncidentScore >= 4) {
        return "success"
      } else if (this.averageIncidentScore >= 3) {
        return "primary"
      } else if (this.averageIncidentScore >= 2) {
        return "warning"
      } else if (this.averageIncidentScore >= 1) {
        return "error"
      } else {
        return "grey darken-1"; // Default color if no score
      }
    },
  },

  methods: {
    ...mapActions("incident_feedback", ["getAll", "removeShow", "getsummarizeFeedback"]),
    async summarizeFeedback() {
        this.isLoading = true;
        try {
            const projectId = this.defaultUserProjects[0].id;
            const payload = { projectId: projectId };
            const response = await this.getsummarizeFeedback(payload);
            this.incidentFeedbackSummary = response.data;
            this.showIncidentFeedbackSummary = true;
        } catch (error) {
            console.error('Error summarizing feedback:', error);
        } finally {
            this.isLoading = false;
        }
    },
    onLike() {
      this.showIncidentFeedbackSummary = false;
    },
    onDislike() {
      this.showIncidentFeedbackSummary = false;
    },

  },

  created() {
    this.filters = {
      ...this.filters,
      ...RouterUtils.deserializeFilters(this.query),
      project: this.defaultUserProjects,
    }

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [
        vm.q,
        vm.itemsPerPage,
        vm.sortBy,
        vm.descending,
        vm.incident,
        vm.rating,
        vm.feedback,
        vm.project,
        vm.participant,
      ],
      () => {
        this.page = 1
        RouterUtils.updateURLFilters(this.filters)
        this.getAll()
      }
    )
  },
}
</script>
